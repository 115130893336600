<template>
  <div class="aside-ymm-container" :class="hideClass">
    <Icon
      container="ymm-icon"
      icon="icon-fa-car-alt"
      @click="drawer = true"
    />

    <el-drawer
      v-if="drawer"
      v-model="drawer"
      title=""
      direction="rtl"
    >
      <YMMComponent
        title="FIND ANOTHER VEHICLE"
        ref="ymmComponentRef"
        :hideQualifiers="false"
      />
    </el-drawer>
  </div>
</template>

<script>
import { ref, watchEffect, inject } from 'vue'
import YMMComponent from '@/components/ymm/YMMComponent'
import { useRoute } from 'vue-router'
import Icon from '@/components/buttons/Icon'
import { useStore } from 'vuex'

export default {
  components: {
    YMMComponent,
    Icon
  },
  setup () {
    const ymmComponentRef = ref(null)
    const drawer = ref(false)
    const hideClass = ref('hidden')
    const route = useRoute()
    const GlobalYMMQualifiers = inject('YMMQualifiersGlobal')
    const store = useStore()

    watchEffect(() => {
      const qualifiersOpened = GlobalYMMQualifiers?.value?.qualifiersOpen || false
      const isHome = route.path === '/'
      if (isHome && !qualifiersOpened) hideClass.value = 'hidden'
      else if (isHome && qualifiersOpened) hideClass.value = ''
      else hideClass.value = ''

      drawer.value = false
      if (store.state.globalYmmModalOpened === true) {
        drawer.value = true
      }
    })

    return {
      drawer,
      ymmComponentRef,
      hideClass
    }
  }
}
</script>

<style lang="scss">
.ymm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 15px;
  margin-left: 20px;
  cursor: pointer;
  color: white;
  width: 30px !important;
  border: none !important;
}

.el-drawer {
  min-width: 355px;
  .el-drawer__body {
    padding-top: 0;
    .ymm-container {
      padding-top: 0;
    }
  }
}
.el-drawer__close {
  color: black !important;
}
.el-drawer__header {
  margin: 0;
  color: #72767b;
  position: absolute;
  padding: 0;
  right: 20px;
  top: 20px;
}
</style>
