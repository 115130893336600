import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/collection/:id',
    name: 'Collection',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "collection" */ '../views/Collection.vue')
  },
  {
    path: '/pdp/:id',
    name: 'PDP',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "pdp" */ '../views/Pdp.vue')
  },
  {
    path: '/pdp/:id/year/:year/make/:make/modelsubmodels/:modelsubmodels/modal/:modal',
    name: 'PDPSearchModal',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "pdp" */ '../views/Pdp.vue')
  },
  {
    path: '/demo',
    name: 'DemoPage',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "demo" */ '../views/Demo.vue')
  },
  {
    path: '/warranty',
    name: 'warrantyPage',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "warranty" */ '../views/Warranty.vue')
  },
  {
    path: '/reportanissue',
    name: 'reportAnIssue',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "report-issue" */ '../views/ReportIssue.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
