import { createStore } from 'vuex'
import { ymmSelectionData } from '@/lib/ymm/YMMSelectionData'
import VuexPersistence from 'vuex-persist'
import merge from 'lodash/merge'
import { getClientCMSData, getCMSConfigObject } from './ButterCMSData'

const vuexPersist = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ ymmData: state.ymmData })
})

export default createStore({
  state: {
    cmsData: {
      globalData: false,
      fields: {
        brand_fields: {
          image_path: 'https://cdn.shopify.com/s/files/1/0432/6644/1375/t/13/assets/logo-header.svg'
        }
      }
    },
    ymmData: {
      dropdownSelections: {},
      parts: [],
      selectedPart: {},
      collectionQueryObj: { slug: '' },
      unqualifiedParts: {}
    },
    globalQualifiersOpened: false,
    globalYmmModalOpened: false,
    displayNoDataAvailable: false,
    globalYmmModalData: {}
  },
  mutations: {
    setCMSData (state, { clientCMSData, globalCMSData }) {
      let tempCmsData = clientCMSData || state.cmsData
      tempCmsData = merge(tempCmsData, globalCMSData)
      state.cmsData = tempCmsData
    },
    setYMMQualifiedData (state, { parts, dropdownSelections, chosenQualifiers, collectionQueryObj }) {
      state.ymmData.parts = parts
      state.ymmData.dropdownSelections = dropdownSelections
      state.ymmData.chosenQualifiers = chosenQualifiers
      state.ymmData.collectionQueryObj = collectionQueryObj
    },
    setSelectedPart (state, _partData) {
      state.ymmData.selectedPart = _partData
    },
    switchGlobalYmmModal (state, { isOpen, displayNoDataAvailable, payload }) {
      state.globalYmmModalOpened = isOpen
      state.displayNoDataAvailable = displayNoDataAvailable
      state.globalYmmModalData = payload
    },
    setYMMDropdownSelections (state, dropdownSelections) {
      state.ymmData.dropdownSelections = dropdownSelections
    }
  },
  actions: {
    async getCMSData ({ commit }) {
      const clientCMSData = await getClientCMSData()
      const globalCMSData = await getCMSConfigObject('microsite-global')
      return commit('setCMSData', { clientCMSData, globalCMSData })
    }
  },
  getters: {
    storex: (store) => {
      return store
    },
    warrantyPageData: ({ cmsData }) => {
      return cmsData?.fields?.warranty_page || false
    },
    ymmCollectionQueryObj: ({ ymmData }) => {
      return ymmData.collectionQueryObj
    },
    ymmDropdownSelections: ({ ymmData }) => {
      return ymmData.dropdownSelections
    },
    ymmCollectionData: ({ ymmData }) => {
      const collectionData = ymmSelectionData.getCollectionData(ymmData)
      return collectionData
    },
    pdpData: ({ ymmData }) => {
      const pdpData = ymmSelectionData.getPDPData(ymmData.selectedPart)
      return pdpData
    },
    cmsHeaderLogo: ({ cmsData }) => {
      const brandFields = cmsData?.fields?.brand_fields || false
      const imgPath = brandFields.image_path || brandFields.uploaded_image || false
      return imgPath
    },
    cmsStyles: ({ cmsData }) => {
      const brandColors = cmsData?.fields?.brand_colors || {}
      return brandColors
    },
    cmsExternalClientPdpButton: ({ cmsData }) => {
      const customFields = cmsData?.fields?.custom_fields || {}
      const externalClientPdpButton = customFields?.external_client_pdp_button || 'DONOTUSE'
      return externalClientPdpButton
    }
  },
  plugins: [vuexPersist.plugin]
})
