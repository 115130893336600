import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import ButterCMS from 'buttercms'

let clientCMSKey

export async function getClientCMSData () {
  const cmsKey = getClientCMSKey()
  return await getCMSConfigObject(cmsKey)
}

export function getClientCMSKey () {
  if (clientCMSKey) {
    return clientCMSKey
  }

  const host = window.location.host
  const splitMF = host.split('.')
  let microsite = (splitMF.length <= 0 || (splitMF.length > 0 && splitMF[0].includes('localhost')) || (splitMF.length > 1 && splitMF[1].includes('azurestaticapps'))) ? process.env.VUE_APP_SELECTED_MICROSITE : splitMF[0]
  if (!microsite.includes('microsite-')) microsite = 'microsite-' + microsite
  clientCMSKey = microsite
  console.log('CMS Key: ', clientCMSKey)

  return clientCMSKey
}

export async function getCMSConfigObject (_resource) {
  const apiResourceGroup = _resource
  // const { apiRequestUrl, apiRequest } = getAPIObjFor('CMSApiKey')(_resource)
  const cmsKeyQuery = getAPIObjFor('CMSApiKey')
  let getCMSKeyForMicrosite = false
  // keep log for live debugging
  // console.log('api req url', apiRequestUrl)
  // if (apiRequestUrl && apiRequest) {
  //   getCMSKeyForMicrosite = await apiRequest(apiRequestUrl)
  // }
  getCMSKeyForMicrosite = await cmsKeyQuery(_resource)
  const cmsApiResourceKey = getCMSKeyForMicrosite || 'nokey'
  const Butter = ButterCMS(cmsApiResourceKey)
  const cmsData = await Butter.page.retrieve('*', apiResourceGroup)
    .then(function (resp) {
      return resp.data.data
    })
    .catch(function (resp) {
      console.log('%cERROR Butter Data, using default values:', 'color:red', resp)
      return false
    })

  return cmsData
}
