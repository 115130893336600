import { computed } from 'vue'
import { useGetters } from '@/lib/helpers'
import variables from '@/styles/_variables.scss'

export function getStyleFor (_type, _prefix = null) {
  const { cmsStyles } = useGetters(['cmsStyles'])
  return computed(() => {
    const cmsValue = cmsStyles.value[_type.toLowerCase()]
    if (cmsValue && _prefix !== null) return `${_prefix}${cmsValue};`
    else if (cmsValue && _prefix === null) return cmsValue
    else return variables[_type]
  }).value
}

export function CMSStyles () {
  return computed(() => {
    return {
      '--header-bg-color': getStyleFor('headerBgColor'),
      '--btn-bg-color': getStyleFor('btnBgColor'),
      '--btn-bg-hover-color': getStyleFor('btnBgHoverColor'),
      '--link-color': getStyleFor('linkColor'),
      '--link-hover-color': getStyleFor('linkHoverColor'),
      '--btn-font-color': getStyleFor('btnFontColor')
    }
  })
}
