<template>
  <div class="ymm-container">

    <YMMDropdowns :title="title" ref="ymmDropdownsRef" @YMM_SELECTED='ymmSelectedHandler' />
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue'

import YMMDropdowns from './YMMDropdowns'
import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import gsap from 'gsap'
import { useStore } from 'vuex'

export default {
  components: { YMMDropdowns },
  props: { title: String, hideQualifiers: { type: Boolean, default: true } },
  setup (props) {
    const ymmQualifiersRef = ref(null)
    const ymmDropdownsRef = ref(null)
    const selectedDropdowns = ref(false)
    const YMMQualifiersGlobal = inject('YMMQualifiersGlobal')
    const partsQueryData = getAPIObjFor('Parts')
    const store = useStore()

    const ymmSelectedHandler = async (_ymmDropdownSelections) => {
      const dropdownSelections = _ymmDropdownSelections
      // const { apiRequestUrl, apiRequest } = partsQueryData(_ymmDropdownSelections)
      // const unqualifiedParts = await apiRequest(apiRequestUrl)
      const unqualifiedParts = await partsQueryData(_ymmDropdownSelections)
      await transitionOutDropdowns()
      selectedDropdowns.value = true
      YMMQualifiersGlobal.value.setupQualifiers({ dropdownSelections, unqualifiedParts })
      if (store.state.globalYmmModalOpened === true) {
        store.commit('switchGlobalYmmModal', { isOpen: false, displayNoDataAvailable: false, payload: null })
      }
    }

    const transitionOutDropdowns = async () => {
      gsap.to('.ymm-dropdowns-container', {
        autoAlpha: 0,
        scale: 0.9,
        duration: 0.5
      })
      await gsap.to('.ymm-container', {
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.21)',
        duration: 0.5
      })
      gsap.set('.ymm-dropdowns-container', { display: 'none' })
    }

    const initDropdowns = () => {
      ymmDropdownsRef.value.resetDropdowns()
      selectedDropdowns.value = false
      if (props.hideQualifiers)gsap.set('.ymm-qualifier-container', { display: 'none' })
      gsap.set('.ymm-dropdowns-container', { display: 'block', autoAlpha: 0, scale: 0.9 })
      gsap.to('.ymm-dropdowns-container', {
        autoAlpha: 1,
        scale: 1,
        duration: 0.5,
        delay: 0.3
      })
    }

    onMounted(() => {
      if (store.state.globalYmmModalOpened === false) {
        initDropdowns()
      }
    })

    return {
      ymmSelectedHandler,
      ymmQualifiersRef,
      initDropdowns,
      ymmDropdownsRef
    }
  }
}
</script>

<style lang="scss" scoped>
.ymm-container {
  margin: 0 auto;
  color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-height: 100%;
    max-width:500px;
  width:100%;
}

</style>
