<template>
  <el-container
    :style="cmsStyles"
    :class="{ hidden: !cmsContentLoaded, fullHeight }"
  >
    <el-header height="auto">
      <Header />
    </el-header>

    <el-main>
      <YMMQualifiers ref="globalYMMQualifiers" />
      <router-view :key="router.path" :class="{ hidden: qualifiersOpened }" />
    </el-main>

    <el-footer height="70px">
      <Footer />
    </el-footer>
 </el-container>
</template>
<script>
import YMMQualifiers from '@/components/ymm/YMMQualifiers'
import { useStore } from 'vuex'
import { CMSStyles } from '@/lib/CustomStyles'
import Header from '@/components/global/Header'
import Footer from '@/components/global/Footer'
import { ref, provide, computed, onMounted, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    Header,
    Footer,
    YMMQualifiers
  },
  setup () {
    const qualifiersOpened = ref(false)
    const store = useStore()
    const cmsStyles = CMSStyles()
    const globalYMMQualifiers = ref(null)
    const cmsContentLoaded = ref(false)
    const router = useRoute()

    const fullHeight = computed(() => router.name === 'Home' || qualifiersOpened.value)
    provide(
      'YMMQualifiersGlobal',
      computed(() => globalYMMQualifiers.value)
    )

    watchEffect(() => {
      qualifiersOpened.value = globalYMMQualifiers?.value?.qualifiersOpen || false
    })

    onMounted(async () => {
      await store.dispatch('getCMSData')
      cmsContentLoaded.value = true
    })

    return {
      cmsStyles,
      globalYMMQualifiers,
      qualifiersOpened,
      cmsContentLoaded,
      fullHeight,
      router
    }
  }
}
</script>

<style lang="scss">
* {
  font-family: "interstate";
  font-size: 13px;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
}
html {
  overflow: hidden;
  overflow-y: auto;
}
// Override Element UI's icon font
$fa-css-prefix: "icon-fa";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
// @include mf-font-awesome();

h1, h1 strong {
  @include mf-bold(36px);
}
p strong {
  @include mf-bold(26px);
}
h2 {
  @include mf-bold(28px);
}
h3 {
  @include mf-bold(18px);
}
.secondary-nav-link {
  @include mf-bold($mf-secondary-nav-font-size);
}

.hidden {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
.autoAlphaHidden {
  opacity: 0;
  visibility: hidden;
}

.is-disabled {
  opacity: 0.6;
}
.underline {
  text-decoration: underline;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $mf-body-color;
  height: inherit;
  .el-header {
    position: fixed;
    width: 100%;
    z-index: 9 !important;
  }
  .el-main {
    margin-top: 60px;
    padding: 0px;
  }
  .el-container {
    min-height: 100%;
    &.fullHeight {
      height: 100%;
    }
  }
  .el-footer,
  .el-header {
    padding: 0;
  }
  .el-footer {
    border-top: $mf-border-color solid thin;
    padding: 0 28px;
  }
  .el-footer,
  .el-header {
    z-index: 2;
  }
}
</style>
