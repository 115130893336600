<template>
  <div class="ymm-dropdowns-container ymm-inner">
    <div class="ymm-header"><h1>{{title}}</h1></div>
    <div class="dropdowns-container">
      <YMMDropdown
        ref="yearRef"
        name="Year"
        labelKey="YearID"
        @OPTION_SELECTED="optionSelectedHandler"
      />
      <YMMDropdown
        ref="makeRef"
        name="Make"
        labelKey="MakeName"
        valueKey="MakeID"
        @OPTION_SELECTED="optionSelectedHandler"
      />
      <YMMDropdown
        ref="modelRef"
        name="Model"
        labelKey="ModelName"
        valueKey="ModelID"
        @OPTION_SELECTED="optionSelectedHandler"
      />
      <YMMDropdown
        ref="subModelRef"
        name="SubModel"
        labelKey="SubModelName"
        valueKey="SubModelID"
        @OPTION_SELECTED="optionSelectedHandler"
      />
      <YMMDropdown
        ref="engineRef"
        name="Engine"
        labelKey="label"
        valueKey="value"
        @OPTION_SELECTED="optionSelectedHandler"
      />
    </div>

    <Button  @click="ymmSubmit"
      :disabled="ymmSearchDisabled"
      class="ymm-submit"
      >NEXT</Button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import YMMDropdown from './YMMDropdown'
import gsap from 'gsap'
import { ymmSelectionData } from '@/lib/ymm/YMMSelectionData'
import Button from '@/components/buttons/Button'
import { useGetters, useMutations } from '@/lib/helpers'
import { useStore } from 'vuex'
const ymmStates = ymmSelectionData.ymmStates
export default {
  props: { title: String },
  components: { YMMDropdown, Button },

  emits: ['YMM_SELECTED'],

  setup (props, { emit }) {
    const groupState = ref({})
    const yearRef = ref(null)
    const makeRef = ref(null)
    const modelRef = ref(null)
    const subModelRef = ref(null)
    const engineRef = ref(null)
    const ymmSearchDisabled = ref(true)
    const preSelect = ref(false)
    const { ymmDropdownSelections } = useGetters(['ymmDropdownSelections'])
    const { setYMMDropdownSelections } = useMutations(['setYMMDropdownSelections'])
    const dropdownRefs = [yearRef, makeRef, modelRef, subModelRef, engineRef]
    const store = useStore()
    const optionSelectedHandler = (_selectedData) => {
      const selectionName = Object.keys(_selectedData)[0]
      const nextDropDownRef = dropdownRefs[ymmStates.indexOf(selectionName) + 1] || false
      groupState.value = { ...groupState.value, ..._selectedData }
      const nextValue = _selectedData[`${selectionName}`].next
      if (nextValue === undefined) {
        if (nextDropDownRef) {
          const preSelectValue = ymmDropdownSelections.value[nextDropDownRef.value.name] || null
          disableDropdownsAfter(selectionName)
          nextDropDownRef.value.updateSelections(groupState.value, preSelectValue)
        } else {
          ymmSearchDisabled.value = preSelect.value = false
        }
      }
    }

    const disableDropdownsAfter = (_dropdownName) => {
      const curStateposition = ymmStates.indexOf(_dropdownName) || 0
      const dropdownsAfter = dropdownRefs.slice(curStateposition + 1)
      dropdownsAfter.forEach((dropdown) => dropdown.value.disableDropdown())
    }

    const resetDropdowns = () => {
      preSelect.value = (Object.keys(ymmDropdownSelections.value).length === 5)
      const preSelectYearsData = preSelect.value ? ymmDropdownSelections.value.Year : null
      disableDropdownsAfter()
      yearRef.value.updateSelections(null, preSelectYearsData)
    }

    const showDropdowns = async () => {
      gsap.to('.ymm-dropdowns-container', {
        autoAlpha: 0,
        scale: 0.9,
        duration: 0.5
      })
      gsap.set('.ymm-dropdowns-container', { display: 'none' })
    }

    const ymmSubmit = async () => {
      // request part data
      emit('YMM_SELECTED', groupState.value)
    }

    onMounted(() => {
      if (store.state.globalYmmModalOpened === true && store.state.globalYmmModalData !== null) {
        var value = store.state.globalYmmModalData.year
        var label = store.state.globalYmmModalData.year
        var selectedYear = {
          Year: {
            value,
            label
          }
        }
        value = store.state.globalYmmModalData.makeID
        label = store.state.globalYmmModalData.makeName
        var selectedMake = {
          Make: {
            value,
            label
          }
        }
        value = store.state.globalYmmModalData.modelID
        label = store.state.globalYmmModalData.modelName
        var selectedModel = {
          Model: {
            value,
            label
          }
        }
        var dropdownSelections = ref({})
        dropdownSelections.value = { ...selectedYear, ...selectedMake, ...selectedModel }
        setYMMDropdownSelections(dropdownSelections)
        yearRef.value.updateSelections(null, { value: store.state.globalYmmModalData.year, label: store.state.globalYmmModalData.year })
      }
    })

    return {
      optionSelectedHandler,
      groupState,
      yearRef,
      makeRef,
      modelRef,
      subModelRef,
      engineRef,
      ymmSearchDisabled,
      ymmSubmit,
      showDropdowns,
      resetDropdowns
    }
  }
}
</script>

<style lang="scss">
.el-select__popper{
  width: 100%;
  left: 0px;
  margin-top: -12px !important;
  .el-popper__arrow{
    display: none;
  }
  .el-select-dropdown__item.selected{
  color: var(--btn-bg-hover-color) !important;
}
}
  .ymm-header{
    h1{margin-top:0;}
  }
  .ymm-dropdowns-container{
    width: 100%;
  }
.dropdowns-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  .el-button.ymm-submit {
    margin-top: 30px;
    min-width:168px;
    @include mf-bold(inherit);
    &.is-disabled {
      filter: none;
    }
  }
</style>
